import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import api from '../utils/api';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate(); // Initialize useNavigate

    const handleRegister = async () => {
        try {
            if (!username || !email || !password) {
                setSnackbarMessage('All fields are required.');
                setSnackbarSeverity('error');
                return;
            }

            if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)) {
                setSnackbarMessage('Invalid email address format.');
                setSnackbarSeverity('error');
                return;
            }

            await api.post('/register', { username, email, password });

            setSnackbarMessage('Registration successful! Redirecting to login...');
            setSnackbarSeverity('success');

            // Redirect to login with username pre-filled
            setTimeout(() => {
                navigate(`/login?username=${encodeURIComponent(username)}`);
            }, 2000); // Delay for Snackbar to display
        } catch (error) {
            let userFriendlyMessage = error.response?.data?.error;
            console.error('Registration error:', userFriendlyMessage || error.message);
            setSnackbarMessage(`Registration failed. ${userFriendlyMessage} Please contact support@actorseverywhere.com.`);
            setSnackbarSeverity('error');
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>
                Register
            </Typography>
            <TextField
                required
                label="Username"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
            />
            <TextField
                required
                label="Email"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
            />
            <TextField
                required
                label="Password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleRegister} sx={{ mt: 2 }}>
                Register
            </Button>
            <Snackbar
                open={!!snackbarMessage}
                autoHideDuration={6000}
                onClose={() => setSnackbarMessage('')}
            >
                <Alert severity={snackbarSeverity} onClose={() => setSnackbarMessage('')}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Register;
