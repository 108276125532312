import axios from 'axios';

// Configure the Axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL, withCredentials: true, // Ensure cookies are sent
});
if (!process.env.REACT_APP_BACKEND_URL) {
    throw new Error('REACT_APP_BACKEND_URL is required');
}

// State to manage token refreshing
let isRefreshing = false;
let subscribers = [];

// Function to notify all subscribers when token refresh is complete
const onTokenRefreshed = (newToken) => {
    subscribers.forEach((callback) => callback(newToken));
    subscribers = [];
};

// Add a subscriber to wait for token refresh
const addSubscriber = (callback) => {
    subscribers.push(callback);
};

// Axios request interceptor to include Authorization header
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => Promise.reject(error));

// Axios response interceptor to handle token expiration
api.interceptors.response.use((response) => response, async (error) => {
    const {config, response} = error;

    // If the request is unauthorized and hasn't been retried yet
    if (response?.status === 401 && !config._retry) {
        // Skip retry logic for login-related API requests
        if (config.url.includes('/login')) {
            console.warn('Skipping retry for login request');
            return Promise.reject(error);
        }
        config._retry = true;

        if (!isRefreshing) {
            isRefreshing = true;

            try {
                // Refresh the token
                console.log('Refreshing token...');
                const refreshResponse = await api.post('/refresh', {
                    refreshToken: localStorage.getItem('refreshToken'),
                }, {withCredentials: true});

                const newToken = refreshResponse.data.token;

                // Store the new token and update headers
                localStorage.setItem('token', newToken);
                api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
                onTokenRefreshed(newToken);
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                isRefreshing = false;

                // Clear tokens and redirect to login
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }

            isRefreshing = false;
        }

        // Wait for the new token to resolve
        return new Promise((resolve) => {
            addSubscriber((newToken) => {
                config.headers['Authorization'] = `Bearer ${newToken}`;
                resolve(api(config));
            });
        });
    }

    return Promise.reject(error);
});

export default api;
