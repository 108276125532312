// Event Detail Page using React and Material UI
import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import { useParams } from 'react-router-dom';
import { Container, Typography, Button, List, ListItem, ListItemText } from '@mui/material';

const EventDetail = ({ user }) => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventResponse = await api.get(`/events/${eventId}`);
        setEvent(eventResponse.data);

        const stagesResponse = await api.get(`/events/${eventId}/stages`);
        setStages(stagesResponse.data);
      } catch (error) {
        console.error('Failed to fetch event details:', error);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  return (
    <Container>
      {event ? (
        <>
          <Typography variant="h4">{event.name}</Typography>
          <Typography variant="body2">
            Start: {new Date(event.startTimestamp).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            End: {new Date(event.endTimestamp).toLocaleString()}
          </Typography>

          <Typography variant="h5" marginTop={4}>Stages</Typography>
          <List>
            {stages.map((stage) => (
              <ListItem key={stage.id}>
                <ListItemText
                  primary={stage.name}
                  secondary={`Start: ${new Date(stage.startTimestamp).toLocaleString()}, End: ${new Date(stage.endTimestamp).toLocaleString()}`}
                />
                {user.roleID === 3 && <Button variant="contained">Vote</Button>}
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <Typography>Loading event details...</Typography>
      )}
    </Container>
  );
};

export default EventDetail;