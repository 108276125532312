import React, {useState} from 'react';
import {
    AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText,
} from '@material-ui/core';
import {Menu as MenuIcon, Close as CloseIcon} from '@material-ui/icons';
import {Link, useNavigate} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }, menuButton: {
        marginRight: theme.spacing(2),
    }, title: {
        flexGrow: 1,
    }, link: {
        color: 'inherit', textDecoration: 'none',
    }, button: {
        marginLeft: theme.spacing(2),
    }, drawerList: {
        width: 250,
    },
}));

const NavigationBar = ({user, onLogout}) => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed

    const handleLogout = () => {
        onLogout();
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const navLinks = [{text: 'Events', to: '/events'}, ...(user ? [] : [{
        text: 'Login',
        to: '/login'
    }, {text: 'Register', to: '/register'},]), ...(user ? [{
        text: `Dashboard (${user.availableVoteCount} votes)`, to: '/dashboard',
    }, {text: 'Logout', action: handleLogout},] : []),];

    return (<div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Event Management System
                    </Typography>
                    {isMobile ? (<>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Drawer
                                anchor="right"
                                open={drawerOpen}
                                onClose={toggleDrawer(false)}
                            >
                                <div
                                    className={classes.drawerList}
                                    role="presentation"
                                    onClick={toggleDrawer(false)}
                                    onKeyDown={toggleDrawer(false)}
                                >
                                    <IconButton
                                        edge="end"
                                        onClick={toggleDrawer(false)}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                    <List>
                                        {navLinks.map((link, index) => link.action ? (<ListItem
                                                button
                                                key={index}
                                                onClick={link.action}
                                            >
                                                <ListItemText primary={link.text}/>
                                            </ListItem>) : (<ListItem
                                                button
                                                key={index}
                                                component={Link}
                                                to={link.to}
                                                className={classes.link}
                                            >
                                                <ListItemText primary={link.text}/>
                                            </ListItem>))}
                                    </List>
                                </div>
                            </Drawer>
                        </>) : (navLinks.map((link, index) => link.action ? (<Button
                                key={index}
                                color="inherit"
                                className={classes.button}
                                onClick={link.action}
                            >
                                {link.text}
                            </Button>) : (<Button
                                key={index}
                                color="inherit"
                                className={classes.button}
                                component={Link}
                                to={link.to}
                            >
                                {link.text}
                            </Button>)))}
                </Toolbar>
            </AppBar>
        </div>);
};

export default NavigationBar;
