import React, {useEffect, useRef, useState} from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import {ArrowBackIos, ArrowForwardIos, Delete, Edit} from '@mui/icons-material';
import {format} from 'date-fns';

const EventCarousel = ({ events, selectedEvent, setSelectedEvent, onEditEvent, onDeleteEvent }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [sortedEvents, setSortedEvents] = useState([]);
    const carouselRef = useRef(null);

    useEffect(() => {
        if (events.length > 0) {
            const sorted = [...events].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setSortedEvents(sorted);

            if (selectedEvent) {
                const defaultIndex = sorted.findIndex(event => event.id === selectedEvent.id);
                setCurrentIndex(defaultIndex >= 0 ? defaultIndex : 0);
            }
        }
    }, [events, selectedEvent]);

    useEffect(() => {
        if (carouselRef.current && sortedEvents.length > 0) {
            const container = carouselRef.current;
            const cardWidth = container.firstChild.offsetWidth + 32; // Card width + margin
            container.scrollLeft = cardWidth * currentIndex - container.offsetWidth / 2 + cardWidth / 2; // Direct adjustment for stable scrolling
        }
    }, [currentIndex, sortedEvents]);

    const handlePrevious = () => {
        const newIndex = (currentIndex - 1 + sortedEvents.length) % sortedEvents.length;
        setCurrentIndex(newIndex);
        setSelectedEvent(sortedEvents[newIndex]);
    };

    const handleNext = () => {
        const newIndex = (currentIndex + 1) % sortedEvents.length;
        setCurrentIndex(newIndex);
        setSelectedEvent(sortedEvents[newIndex]);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '16px',
                overflow: 'hidden',
            }}
        >
            {/* Previous Button */}
            <IconButton onClick={handlePrevious} disabled={sortedEvents.length === 0}>
                <ArrowBackIos />
            </IconButton>

            {/* Events */}
            <Box
                ref={carouselRef}
                sx={{
                    display: 'flex',
                    overflowX: 'hidden',
                    width: '80%',
                }}
            >
                {sortedEvents.map((event, index) => (
                    <Box
                        key={event.id}
                        onClick={() => {
                            setSelectedEvent(event);
                            setCurrentIndex(index);
                        }}
                        sx={{
                            flex: '0 0 300px',
                            margin: '0 16px',
                            padding: '16px',
                            textAlign: 'center',
                            borderRadius: '8px',
                            backgroundColor:
                                selectedEvent?.id === event.id
                                    ? 'primary.main'
                                    : 'background.paper',
                            color:
                                selectedEvent?.id === event.id ? 'white' : 'text.primary',
                            border:
                                selectedEvent?.id === event.id
                                    ? '2px solid primary.light'
                                    : '1px solid #ccc',
                            boxShadow: selectedEvent?.id === event.id ? 4 : 2,
                            transition: 'all 0.3s ease-in-out',
                        }}
                    >
                        <Typography variant="h6">{event.name}</Typography>
                        <Typography variant="body2">
                            {format(new Date(event.startTimestamp), 'MMM d, yyyy h:mm a')}
                            <br />
                            {format(new Date(event.endTimestamp), 'MMM d, yyyy h:mm a')}
                        </Typography>

                        {/* Edit and Delete Buttons */}
                        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between' }}>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onEditEvent(event);
                                }}
                            >
                                <Edit fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteEvent(event.id, event.name);
                                }}
                            >
                                <Delete fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                ))}
            </Box>

            {/* Next Button */}
            <IconButton onClick={handleNext} disabled={sortedEvents.length === 0}>
                <ArrowForwardIos />
            </IconButton>
        </Box>
    );
};

export default EventCarousel;
// Compare this snippet from scratch_5.js: