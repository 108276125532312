import React, {useEffect, useState} from 'react';
import {
    Container,
    Typography,
    Card,
    CardContent,
    Button,
    Box,
    Grid,
    CardMedia,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Switch,
    FormControlLabel,
    LinearProgress,
    Divider

} from '@mui/material';
import api from "../utils/api";
import {format} from 'date-fns';
import {CircularProgress} from '@mui/material';

const EventsPage = ({user, setUser}) => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [expandedEntryId, setExpandedEntryId] = useState(null);
    const [voteDialogOpen, setVoteDialogOpen] = useState(false);
    const [voteCount, setVoteCount] = useState(1);
    const [selectedEntryForVote, setSelectedEntryForVote] = useState(null);
    const [alert, setAlert] = useState({open: false, message: '', severity: 'info'});
    const [availableVoteCount, setAvailableVoteCount] = useState(0);
    const isLoggedIn = !!user; // True if `user` is defined
    const [finalVoteConfirmationOpen, setFinalVoteConfirmationOpen] = useState(false);
    const [hidePastEvents, setHidePastEvents] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');


    useEffect(() => {
        api.get('/events')
            .then(response => {
                const currentTimestamp = new Date().getTime();
                const sortedEvents = response.data.sort((a, b) => {
                    const aEnd = new Date(a.endTimestamp).getTime();
                    const bEnd = new Date(b.endTimestamp).getTime();
                    const aStart = new Date(a.startTimestamp).getTime();
                    const bStart = new Date(b.startTimestamp).getTime();

                    if (aEnd < currentTimestamp && bEnd < currentTimestamp) {
                        return bEnd - aEnd;
                    } else if (aStart > currentTimestamp && bStart > currentTimestamp) {
                        return aStart - bStart;
                    } else if (aEnd >= currentTimestamp && bEnd < currentTimestamp) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                setEvents(sortedEvents);
                setIsLoading(false); // Loading complete
            })
            .catch(error => console.error('Error fetching events:', error));
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get('/users/me');
                setAvailableVoteCount(response.data.availableVoteCount || 0);
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (isLoggedIn) {
            fetchUserData().catch((error) => {
                console.error('Error fetching user data:', error);
            });
        }
    }, [isLoggedIn]);

    const handleFinalVoteDialogOpen = () => {
        setFinalVoteConfirmationOpen(true);
    };

    const handleFinalVoteDialogClose = () => {
        setFinalVoteConfirmationOpen(false);
    };

    const handleFinalVoteSubmit = async () => {
        setFinalVoteConfirmationOpen(false);
        await handleVoteSubmit(); // Call the existing vote submission function
    };

    const handleEventClick = (event) => {
        setSelectedEvent(event);
        const currentTimestamp = new Date().getTime();
        const stages = event.stages || [];
        const sortedStages = stages.sort((a, b) => {
            const aEnd = new Date(a.endTimestamp).getTime();
            const bEnd = new Date(b.endTimestamp).getTime();
            const aStart = new Date(a.startTimestamp).getTime();
            const bStart = new Date(b.startTimestamp).getTime();

            if (aEnd < currentTimestamp && bEnd < currentTimestamp) {
                return bEnd - aEnd;
            } else if (aStart > currentTimestamp && bStart > currentTimestamp) {
                return aStart - bStart;
            } else if (aEnd >= currentTimestamp && bEnd < currentTimestamp) {
                return -1;
            } else {
                return 1;
            }
        });
        setSelectedStage(sortedStages[0]);
    };

    const handleStageNavigation = (direction) => {
        if (!selectedEvent || !selectedEvent.stages) return;

        const stages = selectedEvent.stages;
        const currentIndex = stages.findIndex(stage => stage.id === selectedStage?.id);

        let newIndex = currentIndex;
        if (direction === 'next' && currentIndex < stages.length - 1) {
            newIndex = currentIndex + 1;
        } else if (direction === 'prev' && currentIndex > 0) {
            newIndex = currentIndex - 1;
        }

        setSelectedStage(stages[newIndex]);
        setExpandedEntryId(null); // Collapse any expanded entry on stage change
    };

    const toggleEntryExpansion = (entryId) => {
        setExpandedEntryId(prevId => (prevId === entryId ? null : entryId));
    };

    const handleVoteDialogOpen = (entry) => {
        setSelectedEntryForVote(entry);
        setVoteDialogOpen(true);
    };

    const handleVoteDialogClose = () => {
        setVoteDialogOpen(false);
        setSelectedEntryForVote(null);
        setVoteCount(1);
    };

    const handleVoteSubmit = async () => {
        try {
            const response = await api.post(`/entries/${selectedEntryForVote.id}/vote`, {votes: voteCount});
            setAlert({
                open: true,
                message: `You successfully voted ${voteCount} votes on "${selectedEntryForVote.name}".`,
                severity: 'success'
            });
            handleVoteDialogClose();

            // Update the votes based on backend response
            setEvents(prevEvents => prevEvents.map(event => event.id === selectedEvent.id ? {
                ...event, stages: event.stages.map(stage => stage.id === selectedStage.id ? {
                    ...stage, entries: stage.entries.map(entry => entry.id === selectedEntryForVote.id ? {
                        ...entry, voteCount: response.data.totalVotes
                    } : entry)
                } : stage)
            } : event));
        } catch (error) {
            console.error('Error submitting vote:', error);
            setAlert({open: true, message: 'Failed to submit your vote. Please try again.', severity: 'error'});
        }
    };

    const getNextStageName = () => {
        if (!selectedEvent || !selectedStage) return null;
        const stages = selectedEvent.stages;
        const currentIndex = stages.findIndex(stage => stage.id === selectedStage.id);
        return stages[currentIndex + 1]?.name || null;
    };

    const getPrevStageName = () => {
        if (!selectedEvent || !selectedStage) return null;
        const stages = selectedEvent.stages;
        const currentIndex = stages.findIndex(stage => stage.id === selectedStage.id);
        return stages[currentIndex - 1]?.name || null;
    };

    const filteredEvents = events.filter(event => {
        const isPastEvent = new Date(event.endTimestamp) < new Date();
        const matchesSearchQuery = event.name.toLowerCase().includes(searchQuery.toLowerCase());
        return (!hidePastEvents || !isPastEvent) && matchesSearchQuery;
    });

    // Current date for calculations
    const now = new Date();

    // Calculate stage progress (percentage)
    const stageProgress = selectedStage ? Math.min(100, Math.max(0, ((now - new Date(selectedStage.startTimestamp)) / (new Date(selectedStage.endTimestamp) - new Date(selectedStage.startTimestamp))) * 100)) : 0;

    // Calculate time left (days and hours)
    const timeLeft = selectedStage ? Math.max(0, new Date(selectedStage.endTimestamp) - now) : 0;
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));


    // noinspection XmlDeprecatedElement
    return (<Container>
        <Box sx={{
            display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: 2, gap: 2,
        }}
        >
            {/* Top Row: Events Heading and Toggle */}
            <Box
                sx={{
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: '1 1 100%',
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Events
                </Typography>
                <FormControlLabel
                    control={<Switch
                        checked={hidePastEvents}
                        onChange={() => setHidePastEvents((prev) => !prev)}
                    />}
                    label="Hide Past Events"
                    sx={{
                        marginLeft: 'auto',
                    }}
                />
            </Box>

            {/* Second Row: Search Bar */}
            <Box
                sx={{
                    flex: '1 1 100%', display: 'flex', justifyContent: 'flex-start',
                }}
            >
                <TextField
                    label="Search Events"
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    fullWidth
                    sx={{
                        maxWidth: '400px', minWidth: '200px',
                    }}
                />

            </Box>
        </Box>

        <Box>
            {isLoading ? (<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh'}}>
                <CircularProgress/>
            </Box>) : filteredEvents.length === 0 ? (
                <Typography variant="h6" align="center" color="textSecondary" sx={{marginTop: 4}}>
                    No events found. Try changing the search or toggle filters.
                </Typography>) : (<Grid container spacing={2}>
                {filteredEvents.map(event => (<Grid item xs={12} sm={6} md={4} key={event.id}>
                    <Card
                        elevation={selectedEvent?.id === event.id ? 6 : 1}
                        sx={{
                            cursor: 'pointer',
                            border: selectedEvent?.id === event.id ? '2px solid #1976d2' : 'none',
                            opacity: new Date(event.endTimestamp) < new Date() ? 0.6 : 1,
                            transition: 'transform 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.05)', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                            },
                        }}
                        onClick={() => handleEventClick(event)}
                    >
                        <CardContent>
                            <Typography variant="h6">{event.name}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {`${format(new Date(event.startTimestamp), 'MMM d, yyyy')} - ${format(new Date(event.endTimestamp), 'MMM d, yyyy')}`}
                            </Typography>
                            {new Date(event.endTimestamp) < new Date() && (
                                <Typography variant="caption" color="error">Past Event</Typography>)}
                        </CardContent>
                    </Card>
                </Grid>))}
            </Grid>)}

        </Box>
        <Divider sx={{marginTop: 2, marginBottom: 3}}/>
        {selectedEvent && (<Box sx={{marginTop: 4}}>
            <Typography
                variant="h5"
                gutterBottom
                align="center"
                sx={{
                    fontWeight: 'bold', marginBottom: 1, // Add slight spacing between name and dates
                }}
            >
                {selectedEvent.name}
            </Typography>
            <Typography
                variant="body2"
                gutterBottom
                align="center"
                sx={{
                    color: 'textSecondary',
                }}
            >
                {`${format(new Date(selectedEvent.startTimestamp), 'MMM d, yyyy')} - ${format(new Date(selectedEvent.endTimestamp), 'MMM d, yyyy')}`}
            </Typography>

            {selectedStage && (<Box sx={{marginTop: 4}}>
                {/* Stage Navigation */}
                <Box
                    sx={{
                        display: 'flex', justifyContent: 'center', gap: 2, // Space between buttons
                        marginTop: 3, // Add spacing from the stage details
                        marginBottom: 3,
                    }}
                >
                    {getPrevStageName() && (<Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleStageNavigation('prev')}
                        sx={{
                            display: 'flex', alignItems: 'center', gap: 1, // Space between icon and text
                            padding: '8px 16px', borderRadius: '20px', fontWeight: 'bold', textTransform: 'none', // Avoid all-uppercase for readability
                            '&:hover': {
                                backgroundColor: '#005bb5',
                            },
                        }}
                    >
                        {/* Add Left Arrow Icon */}
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                            ←
                        </Typography>
                        Previous Stage: {getPrevStageName()}
                    </Button>)}
                    {getNextStageName() && (<Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleStageNavigation('next')}
                        sx={{
                            display: 'flex', alignItems: 'center', gap: 1, // Space between icon and text
                            padding: '8px 16px', borderRadius: '20px', fontWeight: 'bold', textTransform: 'none', // Avoid all-uppercase for readability
                            '&:hover': {
                                backgroundColor: '#d32f2f',
                            },
                        }}
                    >
                        {/* Add Right Arrow Icon */}
                        Next Stage: {getNextStageName()}
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                            →
                        </Typography>
                    </Button>)}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 3,
                        marginBottom: 3,
                        padding: 2,
                        borderRadius: 4,
                        backgroundColor: '#f9f9f9',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                        maxWidth: '600px', // Limit the width of the entire container
                        margin: '0 auto', // Center the container
                    }}
                >
                    {/* Stage Name and Dates */}
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                {selectedStage.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {`${format(new Date(selectedStage.startTimestamp), 'MMM d, yyyy')} - ${format(new Date(selectedStage.endTimestamp), 'MMM d, yyyy')}`}
                            </Typography>
                        </Box>

                        {/* Horizontal Progress Bar */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                flex: 1,
                                marginLeft: 2,
                                maxWidth: '300px', // Limit the width of the progress bar
                            }}
                        >
                            <LinearProgress
                                variant="determinate"
                                value={stageProgress}
                                sx={{
                                    height: 6,
                                    flex: 1,
                                    borderRadius: 4,
                                    backgroundColor: '#e0e0e0',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#3f51b5',
                                    },
                                }}
                            />
                            <Typography
                                variant="caption"
                                sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}
                            >
                                {`${Math.round(stageProgress)}%`}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Time Remaining */}
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                            marginTop: 1, textAlign: 'right',
                        }}
                    >
                        {timeLeft > 0 ? `${daysLeft} day(s) ${hoursLeft} hour(s) remaining` : 'Stage has ended.'}
                    </Typography>
                </Box>


                {/* Entries Section */}
                <Box sx={{maxWidth: '600px', margin: '0 auto', marginBottom: 2, marginTop: 2,}}>
                    <Typography variant="h6">Entries:</Typography>
                </Box>
                <Box sx={{maxWidth: '600px', margin: '0 auto'}}>
                    <Grid container spacing={2}>
                        {selectedStage.entries
                            ?.sort((a, b) => (b.voteCount || 0) - (a.voteCount || 0)) // Sort entries by vote count
                            .map(entry => {
                                const votingOpen = new Date() >= new Date(selectedStage.startTimestamp) && new Date() <= new Date(selectedStage.endTimestamp);

                                const highestVoteCount = Math.max(...selectedStage.entries.map(e => parseInt(e.voteCount || 0, 10)));

                                const isWinner = !votingOpen && parseInt(entry.voteCount, 10) === highestVoteCount && selectedStage.entries.filter(e => (parseInt(e.voteCount, 10) || 0) === highestVoteCount).length === 1;

                                const isBehindLeader = votingOpen && selectedStage.entries.length > 1 && parseInt(entry.voteCount, 10) < highestVoteCount;

                                return (<Grid item xs={12} key={entry.id}>
                                    <Card
                                        onClick={() => toggleEntryExpansion(entry.id)}
                                        sx={{
                                            cursor: 'pointer',
                                            transition: 'all 0.3s ease',
                                            boxShadow: expandedEntryId === entry.id ? 6 : 1,
                                            border: isWinner ? '2px solid gold' : expandedEntryId === entry.id ? '2px solid #1976d2' : 'none',
                                            overflow: 'hidden',
                                            position: 'relative',
                                        }}
                                    >
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginBottom: 1,
                                                }}
                                            >
                                                <Typography variant="h6" gutterBottom>
                                                    {entry.name}
                                                </Typography>
                                                <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                                    <Typography
                                                        sx={{
                                                            backgroundColor: '#e0f7fa',
                                                            padding: '4px 8px',
                                                            borderRadius: '12px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                            color: '#00796b',
                                                        }}
                                                    >
                                                        {parseInt(entry.voteCount, 10) || 0} votes
                                                    </Typography>
                                                    {isWinner && !votingOpen && (<Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: 'gold', fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Winner
                                                    </Typography>)}
                                                    {isLoggedIn && parseInt(user.roleID, 10) === 3 && votingOpen && availableVoteCount > 0 && (<Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 'bold', padding: '6px 16px', borderRadius: 20, // Rounded buttons for modern feel
                                                        }}
                                                        onClick={() => handleVoteDialogOpen(entry)}
                                                    >
                                                        {isBehindLeader ? 'Help this entry win!' : 'Vote'}
                                                    </Button>)}
                                                    {isLoggedIn && parseInt(user.roleID, 10) === 3 && votingOpen && availableVoteCount <= 0 && (
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                padding: '6px 16px',
                                                                borderRadius: 20, // Rounded buttons for modern feel
                                                            }}
                                                            onClick={() => (window.location.href = '/dashboard')}
                                                        >
                                                            Purchase Votes
                                                        </Button>)}
                                                </Box>
                                            </Box>
                                            <CardMedia
                                                component="div"
                                                dangerouslySetInnerHTML={{__html: entry.description}}
                                                sx={{
                                                    maxHeight: expandedEntryId === entry.id ? 'auto' : '100px',
                                                    overflow: expandedEntryId === entry.id ? 'visible' : 'hidden',
                                                    padding: 2,
                                                    border: '1px solid #ccc',
                                                    borderRadius: '8px',
                                                    backgroundColor: '#f9f9f9',
                                                }}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>);
                            })}
                    </Grid>
                </Box>


            </Box>)}
        </Box>)}
        {/* Vote Dialog */}
        <Dialog open={voteDialogOpen} onClose={handleVoteDialogClose}>
            <DialogTitle>Vote on "{selectedEntryForVote?.name}"</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Your available votes: {availableVoteCount}
                </Typography>
                <TextField
                    type="number"
                    value={voteCount}
                    onChange={(e) => setVoteCount(Math.max(1, Math.min(Number(e.target.value), availableVoteCount)))}
                    fullWidth
                    label="Number of votes"
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleVoteDialogClose} color="secondary">Cancel</Button>
                <Button
                    onClick={handleFinalVoteDialogOpen}
                    color="primary"
                    disabled={!voteCount || voteCount > (availableVoteCount || 0)}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>

        {/* Final Confirmation Dialog */}
        <Dialog open={finalVoteConfirmationOpen} onClose={handleFinalVoteDialogClose}>
            <DialogTitle>Confirm Your Vote</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    You are about to cast {voteCount} vote(s) for "{selectedEntryForVote?.name}". This action cannot be
                    undone.
                </Typography>
                <Typography variant="body2" color="error">
                    Are you sure you want to proceed?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleFinalVoteDialogClose} color="secondary">Cancel</Button>
                <Button onClick={handleFinalVoteSubmit} color="primary">Yes, Submit</Button>
            </DialogActions>
        </Dialog>
    </Container>);
};

export default EventsPage;
