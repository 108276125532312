import React, {useEffect, useState} from 'react';
import api from '../utils/api';
import {
    Container,
    Typography,
    Button,
    Snackbar,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    Card, CardContent, IconButton,
} from '@mui/material';

import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {Editor} from '@tinymce/tinymce-react';
import 'tinymce/tinymce'; // Core TinyMCE
import 'tinymce/themes/silver'; // Default theme
import 'tinymce/plugins/image'; // Image plugin
import 'tinymce/plugins/media'; // Media plugin
import 'tinymce/plugins/link'; // Link plugin
import 'tinymce/plugins/lists'; // Lists plugin
import EventCarousel from "../components/EventCarousel";
import ConfirmationDialog from "../components/ConfirmationDialog";
import {ArrowBackIos, ArrowForwardIos, Delete} from "@mui/icons-material";

const CoordinatorDashboard = ({user}) => {
    const [events, setEvents] = useState([]);
    const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
    const [isStageDialogOpen, setIsStageDialogOpen] = useState(false);
    const [isEntryDialogOpen, setIsEntryDialogOpen] = useState(false);
    const [newEvent, setNewEvent] = useState({name: '', startTimestamp: null, endTimestamp: null});
    const [newStage, setNewStage] = useState({name: '', startTimestamp: null, endTimestamp: null});
    const [newEntry, setNewEntry] = useState({name: '', description: ''});
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedEntry, setSelectedEntry] = useState(null); // Add this with other states
    const [selectedStage, setSelectedStage] = useState(null);
    const [snackbar, setSnackbar] = useState({open: false, message: '', severity: 'success'});
    const [confirmationDialog, setConfirmationDialog] = useState({
        open: false, title: '', message: '', onConfirm: null,
    });

    const openConfirmationDialog = (title, message, onConfirm) => {
        setConfirmationDialog({
            open: true, title, message, onConfirm,
        });
    };

    const closeConfirmationDialog = () => {
        setConfirmationDialog({
            open: false, title: '', message: '', onConfirm: null,
        });
    };


    useEffect(() => {
        fetchEvents();
    }, []);

    useEffect(() => {
        if (selectedEvent) {
            const updatedEvent = events.find(event => event.id === selectedEvent.id);
            if (updatedEvent) setSelectedEvent(updatedEvent);
        }
    }, [events]);

    const fetchEvents = async () => {
        try {
            const response = await api.get('/coordinator-events');
            setEvents([...response.data]);
        } catch (error) {
            console.error('Failed to fetch events:', error);
        }
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({open: true, message, severity});
    };

    const handleCloseSnackbar = () => {
        setSnackbar({open: false, message: '', severity: 'success'});
    };

    const handleCreateEvent = async () => {
        try {
            const response = await api.post('/events', newEvent);
            setEvents([...events, response.data]);
            setIsEventDialogOpen(false);
            await fetchEvents();
            showSnackbar('Event created successfully!');
        } catch (error) {
            console.error('Failed to create event:', error);
            showSnackbar('Failed to create event!', 'error');
        }
    };

    const handleEditEvent = async () => {
        try {
            const response = await api.put(`/events/${selectedEvent.id}`, newEvent);
            const updatedEvents = events.map(event => event.id === selectedEvent.id ? {...event, ...response.data} : event);
            setEvents([...updatedEvents]);
            setSelectedEvent({...selectedEvent, ...response.data});
            setIsEventDialogOpen(false);
            showSnackbar('Event updated successfully!');
        } catch (error) {
            console.error('Failed to update event:', error);
            showSnackbar('Failed to update event!', 'error');
        }
    };

    const handleDeleteEventWithConfirmation = (eventId, eventName) => {
        openConfirmationDialog('Confirm Event Deletion', `You are about to delete the event "${eventName}". This action cannot be undone. Proceed?`, () => {
            handleDeleteEvent(eventId);
            closeConfirmationDialog();
        });
    };


    const handleDeleteEvent = async (eventId) => {
        try {
            await api.delete(`/events/${eventId}`);
            setEvents(events.filter(event => event.id !== eventId));
            await fetchEvents();
            showSnackbar('Event deleted successfully!');
        } catch (error) {
            console.error('Failed to delete event:', error);
            showSnackbar('Failed to delete event!', 'error');
        }
    };

    const handleCreateStage = async () => {
        const eventStart = new Date(selectedEvent.startTimestamp);
        const eventEnd = new Date(selectedEvent.endTimestamp);
        const stageStart = new Date(newStage.startTimestamp);
        const stageEnd = new Date(newStage.endTimestamp);

        if (stageStart < eventStart || stageEnd > eventEnd) {
            showSnackbar('Stage times must be within the event timeframe!', 'error');
            return;
        }

        if (stageStart >= stageEnd) {
            showSnackbar('Stage end time must be after the start time!', 'error');
            return;
        }

        try {
            const response = await api.post(`/events/${selectedEvent.id}/stages`, newStage);
            setEvents(events.map(event => event.id === selectedEvent.id ? {
                ...event,
                stages: [...(event.stages || []), response.data]
            } : event));
            setIsStageDialogOpen(false);
            showSnackbar('Stage created successfully!');
        } catch (error) {
            console.error('Failed to create stage:', error);
            showSnackbar('Failed to create stage!', 'error');
        }
    };

    const handleEditStage = async () => {
        try {
            const response = await api.put(`/events/${selectedEvent.id}/stages/${selectedStage.id}`, newStage);
            const updatedStages = selectedEvent.stages.map(stage => stage.id === selectedStage.id ? {...stage, ...response.data} : stage);
            const updatedEvent = {...selectedEvent, stages: updatedStages};
            setEvents(events.map(event => event.id === selectedEvent.id ? updatedEvent : event));
            setSelectedEvent(updatedEvent);
            setIsStageDialogOpen(false);
            showSnackbar('Stage updated successfully!');
        } catch (error) {
            console.error('Failed to update stage:', error);
            showSnackbar('Failed to update stage!', 'error');
        }
    };

    const handleDeleteStageWithConfirmation = (stageId, stageName) => {
        openConfirmationDialog('Confirm Stage Deletion', `You are about to delete the stage "${stageName}". This action cannot be undone. Proceed?`, () => {
            handleDeleteStage(stageId);
            closeConfirmationDialog();
        });
    };

    const handleDeleteStage = async (stageId) => {
        try {
            await api.delete(`/events/${selectedEvent.id}/stages/${stageId}`);
            const updatedStages = selectedEvent.stages.filter(stage => stage.id !== stageId);
            const updatedEvent = {...selectedEvent, stages: updatedStages};
            setEvents(events.map(event => event.id === selectedEvent.id ? updatedEvent : event));
            setSelectedEvent(updatedEvent);
            showSnackbar('Stage deleted successfully!');
        } catch (error) {
            console.error('Failed to delete stage:', error);
            showSnackbar('Failed to delete stage!', 'error');
        }
    };

    const handleCreateEntry = async () => {
        try {
            const response = await api.post(`/events/${selectedEvent.id}/stages/${selectedStage.id}/entries`, newEntry);
            setEvents(events.map(event => event.id === selectedEvent.id ? {
                ...event, stages: event.stages.map(stage => stage.id === selectedStage.id ? {
                    ...stage, entries: [...(stage.entries || []), response.data]
                } : stage),
            } : event));
            setIsEntryDialogOpen(false);
            await fetchEvents();
            showSnackbar('Entry created successfully!');
        } catch (error) {
            console.error('Failed to create entry:', error);
            showSnackbar('Failed to create entry!!', 'error');
        }
    };
    const handleEditEntry = async () => {
        if (!selectedEntry) {
            console.error('No entry selected for editing!');
            return;
        }
        try {
            console.log('Updating Entry:', selectedEntry.id, newEntry); // Debug log
            console.log('Selected Event:', selectedEvent); // Debug log
            console.log('Selected Stage:', selectedStage); // Debug log
            const response = await api.put(`/events/${selectedEvent.id}/stages/${selectedStage.id}/entries/${selectedEntry.id}`, newEntry);

            // Update the entries list for the stage
            const updatedEntries = selectedStage.entries.map(entry => entry.id === selectedEntry.id ? {...entry, ...response.data} : entry);

            // Update the selected stage and event
            const updatedStages = selectedEvent.stages.map(stage => stage.id === selectedStage.id ? {
                ...stage, entries: updatedEntries
            } : stage);
            const updatedEvent = {...selectedEvent, stages: updatedStages};

            // Update state
            setEvents(events.map(event => (event.id === selectedEvent.id ? updatedEvent : event)));
            setSelectedEvent(updatedEvent);
            setIsEntryDialogOpen(false);
            showSnackbar('Entry updated successfully!');
        } catch (error) {
            console.error('Failed to update entry:', error);
            showSnackbar('Failed to update entry!', 'error');
        }
    };


    const handleDeleteEntry = async (entryId) => {
        if (!selectedEvent || !selectedStage) {
            console.error('Invalid state: selectedEvent or selectedStage is null');
            showSnackbar('Failed to delete entry: Invalid state!', 'error');
            return;
        }

        try {
            // Call the simplified API endpoint
            await api.delete(`/entries/${entryId}`);

            // Update the frontend state to remove the deleted entry
            const updatedStages = selectedEvent.stages.map(stage => stage.id === selectedStage.id ? {
                ...stage, entries: stage.entries.filter(entry => entry.id !== entryId)
            } : stage);

            const updatedEvent = {...selectedEvent, stages: updatedStages};
            setEvents(events.map(event => (event.id === selectedEvent.id ? updatedEvent : event)));
            setSelectedEvent(updatedEvent);

            showSnackbar('Entry deleted successfully!');
        } catch (error) {
            console.error('Failed to delete entry:', error);
            showSnackbar('Failed to delete entry!', 'error');
        }
    };


    const handleOpenEditEventDialog = (event) => {
        setSelectedEvent(event);
        setNewEvent({
            ...event,
            startTimestamp: event.startTimestamp ? new Date(event.startTimestamp) : null,
            endTimestamp: event.endTimestamp ? new Date(event.endTimestamp) : null,
        });
        setIsEventDialogOpen(true);
    };

    const handleOpenEditStageDialog = (stage) => {
        setSelectedStage(stage);
        setNewStage({
            ...stage,
            startTimestamp: stage.startTimestamp ? new Date(stage.startTimestamp) : null,
            endTimestamp: stage.endTimestamp ? new Date(stage.endTimestamp) : null,
        });
        setIsStageDialogOpen(true);
    };

    return (<Container>
        <Typography variant="h4" gutterBottom>{user.username}'s Dashboard (Coordinator)</Typography>
        <Button
            variant="contained"
            onClick={() => {
                setSelectedEvent(null); // Clear selectedEvent
                setNewEvent({name: '', startTimestamp: null, endTimestamp: null}); // Clear newEvent

                // Delay opening the dialog until after the state has been updated
                setTimeout(() => {
                    console.log('Create Event Clicked: selectedEvent should now be null:', selectedEvent); // Verify state reset
                    setIsEventDialogOpen(true); // Open the dialog
                }, 0);
            }}
            sx={{marginBottom: 2}}
        >
            Create Event
        </Button>


        {/* Event Carousel */}
        <EventCarousel
            events={events}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            onEditEvent={(event) => {
                setSelectedEvent(event);
                setNewEvent({
                    ...event,
                    startTimestamp: event.startTimestamp ? new Date(event.startTimestamp) : null,
                    endTimestamp: event.endTimestamp ? new Date(event.endTimestamp) : null,
                });
                setIsEventDialogOpen(true);
            }}
            onDeleteEvent={handleDeleteEventWithConfirmation}
        />


        {/* Event List */}


        {/* Selected Event Details */}
        {selectedEvent && (<Box sx={{marginTop: 4}}>
            <Typography variant="h5">Selected Event: {selectedEvent.name}</Typography>
            <Button
                variant="contained"
                onClick={() => {
                    setSelectedStage(null); // Reset selectedStage to ensure it's a new stage
                    setNewStage({name: '', startTimestamp: null, endTimestamp: null}); // Clear the stage form
                    setIsStageDialogOpen(true); // Open the dialog
                }}
                sx={{marginTop: 2}}
            >
                Add Stage
            </Button>

            <Box sx={{marginTop: 2}}>
                {selectedEvent.stages?.map(stage => (<Card key={stage.id} sx={{marginBottom: 2}}>
                    <CardContent>
                        <Typography variant="h6">{stage.name}</Typography>
                        <Typography variant="body2">
                            {new Date(stage.startTimestamp).toLocaleString()} - {new Date(stage.endTimestamp).toLocaleString()}
                        </Typography>
                        <Box sx={{marginTop: 1}}>
                            <Button
                                variant="outlined"
                                onClick={() => handleOpenEditStageDialog(stage)}
                            >
                                Edit Stage
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setSelectedEntry(null); // Reset selectedEntry to indicate a new entry
                                    setSelectedStage(stage); // Set the selected stage
                                    setNewEntry({name: '', description: ''}); // Reset newEntry to a blank state
                                    setIsEntryDialogOpen(true); // Open the dialog
                                }}
                            >
                                Add Entry
                            </Button>
                            <IconButton onClick={() => handleDeleteStageWithConfirmation(stage.id, stage.name)}>
                                <Delete/>
                            </IconButton>
                        </Box>
                        <Box sx={{marginTop: 2}}>
                            <Typography variant="h6" sx={{marginBottom: 2}}>Entries</Typography>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                {/* Left Arrow */}
                                <Button
                                    onClick={() => {
                                        const container = document.getElementById(`entries-container-${stage.id}`);
                                        container.scrollBy({left: -300, behavior: 'smooth'});
                                    }}
                                    disabled={(stage.entries || []).length <= 3} // Use fallback to empty array
                                    sx={{minWidth: 0}}
                                >
                                    <ArrowBackIos/>
                                </Button>
                                {/* Entries Container */}
                                <Box
                                    id={`entries-container-${stage.id}`}
                                    sx={{
                                        display: 'flex', overflowX: 'auto', padding: 1, gap: 2, flex: '1 1 auto',
                                    }}
                                >
                                    {(stage.entries || []).map(entry => ( // Use fallback to empty array
                                        <Card
                                            key={entry.id}
                                            sx={{
                                                minWidth: 200,
                                                maxWidth: 300,
                                                flex: '0 0 auto',
                                                boxShadow: 3,
                                                padding: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    boxShadow: 6,
                                                },
                                            }}
                                        >
                                            <Typography variant="subtitle1" gutterBottom>
                                                {entry.name}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    fontSize: 14,
                                                    lineHeight: 1.5,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}
                                                dangerouslySetInnerHTML={{__html: entry.description}}
                                            />
                                            <Box sx={{
                                                display: 'flex', justifyContent: 'space-between', marginTop: 1
                                            }}>
                                                <Typography
                                                    variant="body2">Votes: {entry.voteCount || 0}</Typography>
                                                <Box>
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        sx={{marginRight: 1}}
                                                        onClick={() => {
                                                            setSelectedEntry(entry); // Set the selected entry
                                                            setSelectedStage(stage); // Set the selected stage
                                                            setNewEntry({...entry}); // Populate dialog with entry details
                                                            setIsEntryDialogOpen(true); // Open the dialog
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            setSelectedStage(stage);
                                                            setSelectedEntry(entry);
                                                            handleDeleteEntry(entry.id); // Ensure handleDeleteEntry uses the updated states
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Card>))}
                                </Box>
                                {/* Right Arrow */}
                                <Button
                                    onClick={() => {
                                        const container = document.getElementById(`entries-container-${stage.id}`);
                                        container.scrollBy({left: 300, behavior: 'smooth'});
                                    }}
                                    disabled={(stage.entries || []).length <= 3} // Use fallback to empty array
                                    sx={{minWidth: 0}}
                                >
                                    <ArrowForwardIos/>
                                </Button>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>))}
            </Box>

        </Box>)}

        {/* Snackbar for Success/Error Messages */}
        <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        >
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                {snackbar.message}
            </Alert>
        </Snackbar>

        <ConfirmationDialog
            open={confirmationDialog.open}
            title={confirmationDialog.title}
            message={confirmationDialog.message}
            onConfirm={confirmationDialog.onConfirm}
            onCancel={closeConfirmationDialog}
        />

        {/* Stage Dialog */}
        <Dialog open={isStageDialogOpen} onClose={() => setIsStageDialogOpen(false)}>
            <DialogTitle>{selectedStage ? 'Edit Stage' : 'Create Stage'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Stage Name"
                    fullWidth
                    value={newStage.name}
                    onChange={(e) => setNewStage({...newStage, name: e.target.value})}
                    sx={{marginBottom: 2}}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label="Start Time"
                        value={newStage.startTimestamp}
                        onChange={(date) => setNewStage({...newStage, startTimestamp: date})}
                        renderInput={(props) => <TextField {...props} fullWidth sx={{marginBottom: 2}}/>}
                    />
                    <DateTimePicker
                        label="End Time"
                        value={newStage.endTimestamp}
                        onChange={(date) => setNewStage({...newStage, endTimestamp: date})}
                        renderInput={(props) => <TextField {...props} fullWidth sx={{marginBottom: 2}}/>}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsStageDialogOpen(false)}>Cancel</Button>
                <Button onClick={selectedStage ? handleEditStage : handleCreateStage}>Save</Button>
            </DialogActions>
        </Dialog>
        {/* Entry Dialog with tinymce */}
        <Dialog open={isEntryDialogOpen} onClose={() => setIsEntryDialogOpen(false)}>
            <DialogTitle>{selectedEntry ? 'Edit Entry' : 'Create Entry'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Entry Name"
                    fullWidth
                    value={newEntry.name}
                    onChange={(e) => setNewEntry({ ...newEntry, name: e.target.value })}
                    sx={{ marginBottom: 2 }}
                />
                <Typography>Description:</Typography>
                <Editor
                    value={newEntry.description}
                    onEditorChange={(content) => setNewEntry((prev) => ({ ...prev, description: content }))}
                    init={{
                        height: 300,
                        menubar: false,
                        plugins: ['link', 'image', 'media', 'lists'],
                        toolbar: 'bold italic underline | link image media | bullist numlist | undo redo',
                        image_advtab: true,
                        image_uploadtab: false, // Disable image upload
                        file_picker_callback: (callback, value, meta) => {
                            if (meta.filetype === 'image') {
                                // Prompt for image URL
                                const imageUrl = prompt('Enter the URL of the image:');
                                if (imageUrl) callback(imageUrl, { alt: 'Image description' });
                            } else if (meta.filetype === 'media') {
                                // Prompt for video URL
                                const videoUrl = prompt('Enter the URL of the video:');
                                if (videoUrl) {
                                    // Embed the video as an iframe
                                    callback(videoUrl, {
                                        source2: videoUrl,
                                        poster: '',
                                    });
                                }
                            }
                        },
                        media_url_resolver: (data, resolve) => {
                            // For additional customization of embedded media
                            if (data.url.includes('youtube.com') || data.url.includes('vimeo.com')) {
                                resolve({
                                    html: `<iframe src="${data.url}" width="400" height="300" frameborder="0" allowfullscreen></iframe>`,
                                });
                            } else {
                                resolve({
                                    html: `<video controls src="${data.url}" width="400" height="300"></video>`,
                                });
                            }
                        },
                        setup: (editor) => {
                            console.log('TinyMCE Editor Loaded:', editor);
                        },
                        base_url: '/tinymce', // Path to TinyMCE resources
                        api_key: '727y30taxuiku2z25iea2ek4zw1vfm36p2lknd1nub7ilorl',
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsEntryDialogOpen(false)}>Cancel</Button>
                <Button onClick={selectedEntry ? handleEditEntry : handleCreateEntry}>
                    {selectedEntry ? 'Save Changes' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>


        {/* Event Dialog */}
        <Dialog open={isEventDialogOpen} onClose={() => setIsEventDialogOpen(false)}>
            <DialogTitle>{selectedEvent === null ? 'Create Event' : 'Edit Event'}</DialogTitle> {/* Explicit check */}
            <DialogContent>
                <TextField
                    label="Event Name"
                    fullWidth
                    value={newEvent.name}
                    onChange={(e) => setNewEvent({...newEvent, name: e.target.value})}
                    sx={{marginBottom: 2}}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label="Start Time"
                        value={newEvent.startTimestamp}
                        onChange={(date) => setNewEvent({...newEvent, startTimestamp: date})}
                        renderInput={(props) => <TextField {...props} fullWidth sx={{marginBottom: 2}}/>}
                    />
                    <DateTimePicker
                        label="End Time"
                        value={newEvent.endTimestamp}
                        onChange={(date) => setNewEvent({...newEvent, endTimestamp: date})}
                        renderInput={(props) => <TextField {...props} fullWidth sx={{marginBottom: 2}}/>}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsEventDialogOpen(false)}>Cancel</Button>
                <Button onClick={selectedEvent === null ? handleCreateEvent : handleEditEvent}>
                    {selectedEvent === null ? 'Create Event' : 'Save Changes'}
                </Button>
            </DialogActions>
        </Dialog>


    </Container>);
};

export default CoordinatorDashboard;
