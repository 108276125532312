// Updated Dashboard.js with Auto-Updating UI and Success Notifications

import CoordinatorDashboard from "./CoordinatorDashboard";
import UserDashboard from "./UserDashboard";
import AdminDashboard from "./AdminDashboard";

const Dashboard =  ({ user, setUser})  => {
    if (user.roleID === 1) {
        return <AdminDashboard user={user} />;
    }

    if (user.roleID === 2) {
        return <CoordinatorDashboard user={user} />;
    }

    return <UserDashboard user={user} setUser={setUser} />;
};

export default Dashboard;