// Login.js using React and Material UI
import React, {useState, useEffect} from 'react';
import {TextField, Button, Container, Typography} from '@mui/material';
import {useSearchParams, useNavigate} from 'react-router-dom';
import api from "../utils/api";

const Login = ({onLoginSuccess}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const prefilledUsername = searchParams.get('username');
        if (prefilledUsername) {
            setUsername(prefilledUsername);
        }
    }, [searchParams]);

    const handleLogin = async (e) => {
        e.preventDefault(); // Prevent default form submission
        try {
            if (!username || !password) {
                setErrorMessage('Both username and password are required.');
                return;
            }

            // Step 1: Attempt login
            const response = await api.post('/login', {username, password});

            // Check if login was successful
            if (response.status === 200 && response.data.token) {
                const {token, refreshToken} = response.data;

                // Step 2: Save tokens in localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);

                // Step 3: Fetch user information after login
                const userResponse = await api.get('/users/me', {
                    headers: {Authorization: `Bearer ${token}`},
                });

                // Step 4: Set the user state with fetched user information
                onLoginSuccess(userResponse.data);

                // Step 5: Redirect based on role
                if (userResponse.data.roleID === 1 || userResponse.data.roleID === 2) {
                    navigate('/dashboard');
                } else if (userResponse.data.roleID === 3) {
                    navigate('/events');
                }
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');

                setErrorMessage('Login failed. Please check your credentials.');
            }
        } catch (error) {
            if (error.request.status === 429) {
                setErrorMessage('Please try logging in later.'); // comment
            } else {
                setErrorMessage('Login failed. Please check your credentials.');
            }
        }
    };

    return (<Container maxWidth="sm">
        <Typography variant="h4" gutterBottom>Login</Typography>
        <form onSubmit={handleLogin}>
            <TextField
                label="Username"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                required
            />
            <TextField
                label="Password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                required
            />
            {errorMessage && (<Typography color="error">{errorMessage}</Typography>)}
            <Button type="submit" variant="contained" color="primary">
                Login
            </Button>
        </form>
    </Container>);
};

export default Login;
