import React, {useState, useEffect} from "react";
import {
    Container, Typography, TextField, Button, Box, Alert,
} from "@mui/material";
import api from "../utils/api";
import {loadStripe} from "@stripe/stripe-js";
import {useSearchParams} from "react-router-dom";

const UserDashboard = ({user, setUser}) => {
    const [availableVoteCount, setAvailableVoteCount] = useState(0);
    const [votesToPurchase, setVotesToPurchase] = useState(1);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isLoggedIn = !!user; // True if `user` is defined
    const stripePromise = loadStripe("pk_test_N7vD8xZqc7GvkIzL92dyq49T");
    const [searchParams] = useSearchParams();
    const [successMessage, setSuccessMessage] = useState("");

    const fetchUserData = async () => {
        try {
            const response = await api.get('/users/me');
            setAvailableVoteCount(response.data.availableVoteCount || 0);
            setUser(response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchUserData().catch((error) => {
                console.error('Error fetching user data:', error);
            });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        // Handle success or failure redirects
        const success = searchParams.get("success");
        const canceled = searchParams.get("canceled");

        if (success) {
            const votesPurchased = searchParams.get("votes") || 0;
            setSuccessMessage(`Success! You purchased ${votesPurchased} votes.`);
            fetchUserData().catch((error) => {
                console.error('Error fetching user data:', error);
            });
        } else if (canceled) {
            setError("Purchase canceled. Please try again.");
        }
    }, [searchParams]);

    const handlePurchase = async () => {
        setError("");
        setIsLoading(true);

        try {
            const response = await api.post("stripe/create-checkout-session", {votes: votesToPurchase});
            const {sessionId} = response.data;

            if (sessionId) {
                const stripe = await stripePromise;
                await stripe.redirectToCheckout({sessionId: sessionId});
            } else {
                throw new Error("Failed to initiate checkout session.");
            }
        } catch (err) {
            setError(err.message || "An error occurred. Please try again.");
        }

        setIsLoading(false);
    };

    // noinspection XmlDeprecatedElement
    return (<Container maxWidth="sm">
        <Typography variant="h4" gutterBottom>
            {user.username}'s Dashboard
        </Typography>
        <Typography variant="body1">
            Available Votes: {availableVoteCount}
        </Typography>
        {successMessage && (<Alert severity="success" sx={{my: 2}}>
            {successMessage}
        </Alert>)}
        {error && (<Alert severity="error" sx={{my: 2}}>
            {error}
        </Alert>)}
        <Alert severity="info" sx={{my: 2}}>
            All purchases are final. Each vote costs $1.00.
        </Alert>
        <Box sx={{display: "flex", flexDirection: "column", gap: 2, my: 2}}>
            <TextField
                label="Votes to Purchase"
                type="number"
                value={votesToPurchase}
                onChange={(e) => setVotesToPurchase(Number(e.target.value))}
                inputProps={{min: 1}}
                fullWidth
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handlePurchase}
                disabled={isLoading || votesToPurchase < 1}
            >
                {isLoading ? "Processing..." : "Purchase Votes"}
            </Button>
            {error && <Alert severity="error">{error}</Alert>}
        </Box>
    </Container>);
};

export default UserDashboard;
