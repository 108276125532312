import React, {useEffect, useState} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Login from './pages/Login';
import EventsPage from './components/EventsPage';
import EventDetail from './pages/EventDetail';
import NotFoundPage from './components/NotFoundPage';
import api from "./utils/api";

const App = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // New loading state
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            api.get('/users/me')
                .then((response) => setUser(response.data))
                .catch((error) => {
                    console.error('Error fetching user details:', error);
                    handleLogout(); // Automatically handle expired or invalid token
                })
                .finally(() => setLoading(false)); // Loading finished
        } else {
            setLoading(false); // No token, loading finished
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        api.defaults.headers.common['Authorization'] = null;
        setUser(null);
        navigate('/login');
    };

    if (loading) {
        // Show a loading indicator while authentication check is in progress
        return <div>Loading...</div>;
    }

    return (
        <>
        <NavigationBar user={user} onLogout={handleLogout}/>
        <Routes>
            <Route path="/" element={<EventsPage user={user} setUser={setUser}/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/login" element={<Login onLoginSuccess={setUser}/>}/>
            <Route path="/events" element={<EventsPage user={user} setUser={setUser}/>}/>
            <Route path="/events/:eventId" element={<EventDetail/>}/>
            <Route path="/dashboard" element={<PrivateRoute user={user}>
                <Dashboard user={user} setUser={setUser}/>
            </PrivateRoute>}/>
            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
    </>);
};

export default App;
