import React, {useState, useEffect} from 'react';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Select,
    Snackbar,
    Alert,
} from '@mui/material';
import api from '../utils/api';

const AdminDashboard = ({user}) => {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isCreateMode, setIsCreateMode] = useState(false);

    useEffect(() => {
        fetchUsers();
        fetchRoles();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await api.get('/users/all');
            setUsers(response.data);
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await api.get('/roles/all');
            setRoles(response.data);
        } catch (error) {
            console.error('Failed to fetch roles:', error);
        }
    };

    const handleCreateUser = async () => {
        try {
            const response = await api.post('/users', selectedUser);
            setUsers([...users, response.data]);
            setSnackbarMessage('User created successfully!');
            setSnackbarSeverity('success');
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Failed to create user:', error);
        // Extract error message from server response
        const errorMessage = error.response?.data?.error || 'Failed to create user!';
        setSnackbarMessage(errorMessage);
            setSnackbarSeverity('error');
        }
    };

    const handleEditUser = async () => {
        try {
            await api.put(`/users/${selectedUser.id}`, selectedUser);
            setUsers(users.map(user => (user.id === selectedUser.id ? selectedUser : user)));
            setSnackbarMessage('User updated successfully!');
            setSnackbarSeverity('success');
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Failed to edit user:', error);
        // Extract error message from server response
        const errorMessage = error.response?.data?.error || 'Failed to update user!';
        setSnackbarMessage(errorMessage);
            setSnackbarSeverity('error');
        }
    };

    const handleOpenDialog = (user = null, createMode = false) => {
        setSelectedUser(user || {username: '', email: '', availableVoteCount: 0, roleID: ''});
        setIsCreateMode(createMode);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    // noinspection XmlDeprecatedElement
    return (<Container>
        <Typography variant="h4" gutterBottom>
            {user.username}'s  Dashboard (Admin)
        </Typography>
        <Button
            variant="contained"
            onClick={() => handleOpenDialog(null, true)}
            sx={{marginBottom: 2}}
        >
            Create User
        </Button>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Available Votes</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(user => (<TableRow key={user.id}>
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.availableVoteCount}</TableCell>
                        <TableCell>{roles.find(role => role.id === user.roleID)?.name}</TableCell>
                        <TableCell>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => handleOpenDialog(user, false)}
                            >
                                Edit
                            </Button>
                        </TableCell>
                    </TableRow>))}
                </TableBody>
            </Table>
        </TableContainer>

        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>{isCreateMode ? 'Create User' : 'Edit User'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Username"
                    fullWidth
                    variant="outlined"
                    placeholder="Enter username"
                    value={selectedUser?.username || ''}
                    onChange={e => setSelectedUser({...selectedUser, username: e.target.value})}
                    sx={{marginBottom: 2}}
                />
                <TextField
                    label="Email"
                    fullWidth
                    variant="outlined"
                    placeholder="Enter email address"
                    value={selectedUser?.email || ''}
                    onChange={e => setSelectedUser({...selectedUser, email: e.target.value})}
                    sx={{marginBottom: 2}}
                />
                <TextField
                    label="Available Votes"
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder="Enter number of votes available"
                    value={selectedUser?.availableVoteCount || 0}
                    onChange={e => setSelectedUser({
                        ...selectedUser, availableVoteCount: parseInt(e.target.value, 10),
                    })}
                    sx={{marginBottom: 2}}
                />
                <Select
                    value={selectedUser?.roleID || ''}
                    onChange={e => setSelectedUser({...selectedUser, roleID: e.target.value})}
                    fullWidth
                    displayEmpty
                    sx={{marginBottom: 2}}
                    variant="outlined"
                >
                    <MenuItem disabled value="">
                        Select a Role
                    </MenuItem>
                    {roles.map(role => (<MenuItem key={role.id} value={role.id}>
                        {role.name}
                    </MenuItem>))}
                </Select>
                <TextField
                    label="Password"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true, // Ensures label doesn't overlap placeholder
                    }}
                    type="password"
                    placeholder={isCreateMode ? 'Enter a strong password' : 'Leave blank to retain current password'}
                    value={selectedUser?.password || ''}
                    onChange={e => setSelectedUser({...selectedUser, password: e.target.value})}
                    sx={{marginBottom: 2}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button
                    onClick={isCreateMode ? handleCreateUser : handleEditUser}
                    variant="contained"
                    color="primary"
                >
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={3000}
            onClose={() => setSnackbarMessage('')}
        >
            <Alert onClose={() => setSnackbarMessage('')} severity={snackbarSeverity}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Container>);
};

export default AdminDashboard;
